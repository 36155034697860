import { required, minLength, maxLength } from 'vuelidate/lib/validators'

// const LATIN_REGEX = helpers.regex('alpha', /^[^а-яА-Я0-9]*$/)
// const NUMBER_REGEX = helpers.regex('alpha', /^[0-9-` '/]*$/)
// const CYRILLIC_REGEX = helpers.regex('alpha', /^[^a-zA-Z0-9ыЫъЪёЁЭэ]*$/)

export const verificationForm = () => ({
  documentNumberForm: {
    // last_name: { 
    //   required, 
    //   LANG_REGEX: context.lang === 'en' ? LATIN_REGEX : CYRILLIC_REGEX
    // },
    number_document: { required },
    type_document: { required },
    date_start: { required }
  },
  documentVerificationForm: {
    uuid_document: { 
      required,
      maxLength: maxLength(22),
      minLength: minLength(22)
     }
  }
})