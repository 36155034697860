<template lang="pug">
include ../../configs/templatesPUG/template.pug

div.wrapper-verification
  div.fs-24.fw-800.font-head-light {{ $t('project.searchDocument') }}
  div.d-flex.cursor-pointer.flex-column.mt-8
    div(v-for="(radio, index) in radioCheckboxes" :key="index" @click="changeDocumentType(radio.type)").d-flex.mb-4
      div(:class="{ 'radio-btn-active': documentType === radio.type }").radio-btn.mr-3
      div.fs-14.fw-300 {{ $t(radio.title) }}
    div(v-if="documentType === 1")
      //- +text-field('documentNumberForm.last_name', 'project.name', '["required", "LANG_REGEX"]')(:label="$t('enterTheDocumentName')").mb-n3
      +text-field('documentNumberForm.number_document', 'project.documentNumber', '["required"]')(:label="$t('enterTheDocumentNumber')").mb-n3
      +select('documentNumberForm.type_document', 'documentTypes', 'project.documentType', '["required"]')(:label="$t('selectTheDocumentType')").mb-n3
      DatePicker(
        :validateType="['required']"
        :errorMessage="$valid.validation($v.documentNumberForm.date_start, ['required'])"
        placeholder="enterTheIssueDate"
        label="project.dateOfIssue"
        v-model="documentNumberForm.date_start")
    div(v-else)
      +text-field('documentVerificationForm.uuid_document', 'project.verificationNumber', '["required", "minLength", "maxLength"]')(:label="$t('enterVerificationNumber')")
    VueRecaptcha(
      ref="recaptcha"
      :sitekey="siteKey"
      :class="{ 'd-none' : recaptchaToken }"
      :loadRecaptchaScript="true"
      :language="this.lang === 'en' ? 'en' : 'uk'"
      @verify="onCaptchaVerified"
      @error="recaptchaToken = null"
      @expired="onCaptchaExpired")
    +btn-full-width('#026B81', 'btn.findDocument')(v-if="!!recaptchaToken" :loading="isLoad" @click="submit").mt-6
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { verificationForm } from '@/configs/validationRules'

export default {
  name: 'VerificationForm',
  components: {
    DatePicker: () => import('@/components/layouts/DatePicker'),
    VueRecaptcha: () => import('vue-recaptcha')
  },
  data () {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
      recaptchaToken: null,
      documentType: 1,
      radioCheckboxes: [
        {
          title: 'btn.searchByDocumentNum',
          type: 1
        },
        {
          title: 'btn.searchByVerificationNum',
          type: 2
        }
      ],
      documentNumberForm: {
        // last_name: '',
        number_document: '',
        type_document: '',
        date_start: ''
      },
      documentVerificationForm: {
        uuid_document: ''
      }
    }
  },
  computed: {
    ...mapState({
      lang: state => state.app.lang,
      isLoad: state => state.app.isLoad
    }),
    documentTypes() {
      return [
        { name: 'diploma', value: 'qualification' },
        { name: 'confirmation', value: 'diploma_confirmation' },
        { name: 'stiCertificate', value: 'diia_certificate_eti' },
        // { name: 'medicalCertificate', value: 'medical' },
        { name: 'seamanRecordBook', value: 'service_book' },
        { name: 'specialistCertificate', value: 'qualification_certificate' },
        { name: 'certificateOfCompetency', value: 'certificate_of_competency' },
        { name: 'endorsementOfCompetency', value: 'endorsement_of_competency' },
        { name: 'rankerCertificate', value: 'ranker_certificate' },
      ].map(type => { return { name: this.$t(`typeOfTheDocuments.${ type.name }`), value: type.value } })
    }
  },
  validations () { return verificationForm(this) },
  mounted () {
    if (this.$route.params.uuid) {
      this.setQRLinkParams()
      this.changeLanguage()
    }
  },
  methods: {
    ...mapActions(['checkValidDocument']),

    changeDocumentType (type) {
      this.documentType = type
      if (this.documentType === 1) Object.keys(this.documentNumberForm).forEach(key => this.documentNumberForm[key] = '')
      else this.documentVerificationForm.uuid_document = ''
      if (this.$route.path.includes('/sailor/su/')) this.$router.push('/')
    },

    setQRLinkParams () {
      this.documentType = 2
      this.documentVerificationForm.uuid_document = this.$route.params.uuid
    },

    changeLanguage () {
      this.$store.commit('SET_DATA_APP', { lang: this.$route.query.lang ? 'en' : 'ua' })
    },

    onCaptchaVerified (token) {
      this.recaptchaToken = token
      this.$store.commit('SET_DATA_APP', { documentNotFound: false })
      if (this.$route.params.uuid) this.$router.push('/')
    },

    onCaptchaExpired () {
      setTimeout(() => {
        this.$refs.recaptcha.reset()
        this.recaptchaToken = null
      }, 10000)
    },

    submit () {
      let currentBody
      if (this.documentType === 1) {
        if (this.$v.documentNumberForm.$invalid) return this.$v.documentNumberForm.$touch()
        currentBody = { ...this.documentNumberForm, recaptcha: this.recaptchaToken }
      } else {
        if (this.$v.documentVerificationForm.$invalid) return this.$v.documentVerificationForm.$touch()
        currentBody = { ...this.documentVerificationForm, recaptcha: this.recaptchaToken }
      }
      this.checkValidDocument(currentBody).then(() => { this.onCaptchaExpired() })
    }
  },
}

</script>